/* You can add global styles to this file, and also import other style files */
body, html {
  height: 100vh;

    background-image: url("src/assets/logo-stern.png");
    background-color: #808080;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    #background-size: cover;
}

.page {

    /* Full height */
    height: 100%;



}



@import "~bootstrap/dist/css/bootstrap.min.css";
